[ui-view].ng-enter,
[ui-view].ng-leave {
  position: absolute;
  left: 0;
  right: 0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

[ui-view].ng-enter {
  opacity: 0;
}

[ui-view].ng-enter-active {
  opacity: 1;
}

[ui-view].ng-leave {
  opacity: 0;
}

[ui-view].ng-leave-active {
  opacity: 1;
}
